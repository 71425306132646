import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { HomeworkModel } from "@/models";
import { APLightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { TeCommon } from "../Common";

export class QuizForm {
  shid = '';
  name = '';
  student = '';
  city = '';
  school = '';
  class = '';
  grade = '';
  unit = '';
  level = '';
  date = '';
  completeDate = '';
  question = [];
  num = 0;
  currentQuestion = 0;
  score = 0;
  token = window.localStorage.getItem('teacherToken') as string;
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkVerifyInfoController>({
  components: {
    Header, APLightbox,Footer
  }
})
export default class ApTeacherHomeworkVerifyInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private quizForm = new QuizForm();
  private token = window.localStorage.getItem('teacherToken') as string;

  private lightbox: { showLB: boolean, errorMsg: string } = {
    showLB: false, 
    errorMsg:  '',
  };

  private errorMsgMap: { [key: string]: string } = {
    score: '',
  };

  public async created() {
    const item = {
      shid: this.$route.query.shid,
      token: this.token,
    }
    const editData = await HomeworkModel.teGetResult(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    TeCommon.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      shid: string;
      name: string;
      student: string;
      city: string;
      school: string;
      class: string;
      grade: string;
      unit: string;
      level: string;
      date: string;
      completeDate: string;
      question: [];
      num: number;
      currentQuestion: number;
      score: number;
    };
    this.quizForm.shid = item.shid;
    this.quizForm.name = item.name;
    this.quizForm.student = item.student;
    this.quizForm.city = item.city;
    this.quizForm.school = item.school;
    this.quizForm.class = item.class;
    this.quizForm.grade = item.grade;
    this.quizForm.unit = item.unit;
    this.quizForm.level = item.level;
    this.quizForm.date = item.date;
    this.quizForm.completeDate = item.completeDate;
    this.quizForm.question = item.question;
    this.quizForm.num = item.num;
    this.quizForm.currentQuestion = item.currentQuestion;
    this.quizForm.score = item.score as number;
  }

  public async validateAndSubmit() {
    if (typeof (this.quizForm.score) !== 'number') {
      this.errorMsgMap = { //先清空error message
        score: '',
      };
      this.errorMsgMap['score'] = '請填入數字'; //放入error message
      return;
    }

    if (!this.doubleClick) {
      this.doubleClick = true;

      const item: { token: string, shid: string, score: number, teacherComment: response } = {
        token: this.token,
        shid: this.quizForm.shid,
        score: this.quizForm.score,
        teacherComment: {},
      }

      for (const [key, option] of Object.entries(this.quizForm.question)) {
        item.teacherComment[key] = {
          hqid: option['hqid'],
          comment: option['comment'],  
        }
      }

      const errNo = await HomeworkModel.teVerify(item);
      switch (errNo) {
        case ErrorCode.InvalidToken:
          TeCommon.logout();
          break;
        case ErrorCode.Success:
          this.doubleClick = false;
          this.$router.push("/teacher/homework/verify");
          break;
        default:
          this.lightbox.errorMsg = ErrorMessage[errNo];
          this.lightbox.showLB = true;
          this.doubleClick = false;
          break;
      }
    }
  }

  private closeLB() {
    this.lightbox.showLB = false;
  }
}